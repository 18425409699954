import Vue from 'vue';
import { ConfigurationService, registry } from '@nsftx/games-config';
import { gamesStatic } from '@nsftx/games-sdk-js';
import {
  assign,
  has,
  upperFirst,
  toLower,
} from 'lodash';
import webViewIntegration from '@/utility/webViewIntegration';
import App from './App';
import router from './router';
import store from './store';
import '@nsftx/games-sdk-js/src/utility/filters';

Vue.config.productionTip = false;

const qp = new URLSearchParams(window.location.search);
const productName = qp.get('productName');
const messageProtocol = qp.get('messageProtocol');
const tenantId = qp.get('tenantId');
const layout = qp.get('layout');
const appName = qp.get('deliveryPlatform') || qp.get('application') || 'web';
const sessionToken = qp.get('token');
const playerId = qp.get('id') || qp.get('playerId');
const clientType = qp.get('authStrategy') || qp.get('clientType');
let deviceData;
let betshopData;
let companyData;
let accountData;

const getRacersColors = (product) => {
  const racerLabelColors = {
    GreyhoundRaces: ['bf3030', '3498db', 'ffffff', '000000', 'd35400', ['000000', '#fff'], '779C08', 'C9AB0A'],
    VirtualMotorcycleSpeedway: ['bf3030', '3498db', 'ffffff', 'ebc40f'],
    VirtualHorseRaces: ['30BF78', '5E411C', 'EBC40F', 'BF3030', '3498DB', '762E95', 'FFFFFF', '000000'],
    VirtualGreyhoundRaces: ['bf3030', '3498db', 'ffffff', '000000', 'd35400', ['000000', '#fff'], '779C08', 'C9AB0A'],
    SlotCarRaces: ['D35400', '999999', '30BF78', '3498DB', 'BF3030', '762E95'],
  };
  return racerLabelColors[product];
};

const getProductNameLabel = (product) => {
  const productNameLabels = {
    GreyhoundRaces: 'pages_player_history_greyhound',
    VirtualGreyhoundRaces: 'pages_player_history_virtual_greyhound',
    VirtualHorseRaces: 'pages_player_history_virtual_horse',
    VirtualMotorcycleSpeedway: 'pages_player_history_virtual_motorcycle_speedway',
    SlotCarRaces: 'pages_player_history_slot_car_races',
  };
  return productNameLabels[product];
};
if (appName === 'terminal') {
  store.dispatch('user/setTerminalUser', true);
  store.dispatch('setIsTerminal', true);
  window.addEventListener('message', (event) => {
    if (event.data.action === 'Slave.Load') {
      const { device } = event.data.data;
      const { betshop } = event.data.data;
      const { company } = event.data.data;
      const { account } = event.data.data;
      if (event.data.data.settings.showCursor) {
        store.dispatch('setTerminalMouseCursorEnabled', event.data.data.settings.showCursor.value);
      }
      deviceData = device;
      betshopData = betshop;
      companyData = company;
      accountData = account;
      store.dispatch('setUserBalance', event.data.data.terminal.balance);
      store.dispatch('setTerminalUserData', event.data.data.user);
      if (event.data.data.betslip.blockers.length) {
        store.dispatch('disableAllBetting', true);
        store.dispatch('gamesBetslip/setBetslipBlockers', {
          blockers: event.data.data.betslip.blockers,
          type: 'add',
        });
      }
    }
    if (event.data.action === 'Betslip.Blocked') {
      store.dispatch('disableAllBetting', true);
      store.dispatch('gamesBetslip/setBetslipBlockers', {
        blockers: event.data.data.blockers,
        type: 'add',
      });
    }
    if (event.data.action === 'Betslip.Unblocked') {
      store.dispatch('disableAllBetting', false);
      store.dispatch('gamesBetslip/setBetslipBlockers', {
        blockers: event.data.data.blockers,
        type: 'remove',
      });
    }
    if (has(event.data.data, 'code') && event.data.data.code === 'T_PAYIN_VALIDATE_INSUFFICIENT_FUNDS') {
      store.dispatch('gamesBetslip/setIsPayinInProgress', false);
      store.dispatch('setScheduleDisabled', false);
      store.dispatch('stopBetting', 0);
      store.dispatch('gamesBetslip/setIsPayinButtonDisabled', false);
    }
    if ((has(event.data.data, 'code') && event.data.data.code === 'error') || ((has(event.data.data, 'code')) && event.data.data.code === 'UNKNOWN') || ((has(event.data.data, 'code')) && event.data.data.code === 'T_PAYIN_VALIDATION_PRINT_STATUS_ERR')) {
      store.dispatch('betslip/lockBetslip', false);
      store.dispatch('setBetslipLockMessage', '');
      store.dispatch('setPayinProgress', false);
      store.dispatch('disable_betting', false);
      store.dispatch('gamesBetslip/setIsPayinButtonDisabled', false);
      store.dispatch('gamesBetslip/setIsPayinInProgress', false);
    }
  });
}
const getSlaveId = (id) => {
  if (id === 'TerminalTicketHistory') {
    store.dispatch('setActiveTerminalLayout', 'RacersHistory');
    return `Plugin${productName}TicketHistory`;
  }
  if (id === 'TerminalResults') {
    store.dispatch('setActiveTerminalLayout', 'RacersResults');
    return `Plugin${productName}Results`;
  }
  if (id === 'TerminalTicketDetails') {
    store.dispatch('setActiveTerminalLayout', 'RacersTicketDetails');
    return `Plugin${productName}TicketDetails`;
  }
  store.dispatch('setActiveTerminalLayout', 'Racers');
  return `Plugin${productName}`;
};
(async () => {
  const configService = new ConfigurationService({
    environment: process.env.VUE_APP_ENVIRONMENT,
    requiredPaths: [],
    messageProtocol,
    applicationName: upperFirst(appName),
    clientAdapters: [
      {
        name: 'GravityGatewayAdapter',
        options: {
          slaveId: getSlaveId(layout),
          eventPropagation: {
            click: true,
            keydown: '*',
          },
        },
      },
    ],
    ui: tenantId,
  });
  const config = await configService.getConfiguration();
  const product = registry.getProduct(config.productName);
  const translations = await gamesStatic.getTranslations(config.locale);
  const [alias] = product.aliases;
  store.state.config.appLayout = layout || config.ui.config.layout || 'Compact';
  if (layout === 'TerminalTicketHistory') {
    store.state.config.appLayout = 'TerminalHistory';
  }
  store.state.config.areas = {
    bettingArea: appName === 'terminal' ? 'RacesBettingAreaATerminal' : 'RacesBettingAreaA',
    eventsArea: 'RacesEventsAreaA',
    betslipArea: store.state.config.appLayout === 'Expanded' ? 'BetslipA' : 'BetslipB',
  };
  store.state.streamConfig.origin.url = process.env.NODE_ENV === 'production' ? '://stream.7platform.live' : '://stream-staging.7platform.live';
  store.state.config.racerLabelColors = getRacersColors(productName);
  store.state.config.productNameLabel = getProductNameLabel(productName);
  store.state.config.productAlias = alias;
  store.state.config.reverseForecast = config.bets[12].value;
  store.state.visualizationUrl = process.env.VUE_APP_VISUALIZATION_BASEURL;
  store.state.config.rules = config.rules;
  store.state.config.taxes = config.taxes;
  if (appName === 'terminal') {
    store.dispatch('setTerminal', true);
    store.state.config.device = deviceData;
    store.state.config.betshop = betshopData;
    store.state.config.companyData = companyData;
    store.state.config.account = accountData;
  }
  store.dispatch('setDeviceLayout');
  store.dispatch('setConfig', config);
  store.dispatch('betslip/setTaxes', config.taxes);
  store.dispatch('setRules', config.rules);
  store.dispatch('setTranslations', assign(translations, config.translations));
  store.dispatch('betslip/setBetslipConfigValues', { ticketCancelAllowed: true, futureBets: 1 });
  if (store.state.config.paymentStrategy.web.value === 'perBet') {
    store.dispatch('betslip/setPaymentPerBet', true);
  }
  const paymentStrategy = {
    paymentStrategy: config.paymentStrategy,
  };
  const isPaymentPerBetStrategy = config.paymentStrategy[toLower(appName)].value === 'perBet';
  const betslipStaticConfig = config.ui?.config?.betslip;
  const mergedRules = { ...config.rules, ...paymentStrategy };
  const betslipConfig = {
    betLayout: betslipStaticConfig?.betLayout ?? 'A',
    channel: config.applicationName,
    ticketTypes: betslipStaticConfig?.ticketTypes ?? ['single'],
    rules: mergedRules,
    taxes: config.taxes,
    isPossibleWinActive: betslipStaticConfig?.isPossibleWinActive ?? true,
    isPaymentBetAllowed: betslipStaticConfig?.isPaymentBetAllowed ?? true,
    isPaymentPerBetAllowed: betslipStaticConfig?.isPaymentPerBetAllowed ?? true,
    isPaymentPerBetStrategy,
    isFuturePerBetAllowed: betslipStaticConfig?.isFuturePerBetAllowed ?? false,
    isFuturePerTicketAllowed: false,
    isTicketCancelAllowed: betslipStaticConfig?.isTicketCancelAllowed ?? true,
    isTicketRebetAllowed: betslipStaticConfig?.isTicketRebetAllowed ?? true,
    isAddButtonActive: false,
    minIncrement: betslipStaticConfig?.minIncrement ?? 0.01,
    futureRounds: 1,
  };
  await store.dispatch('setRules', mergedRules);
  await store.dispatch('gamesBetslip/setConfig', betslipConfig);
  if (sessionToken) {
    store.state.config.token = sessionToken;
    store.state.config.platformConfig.clientType = clientType;
    store.state.config.platformConfig.playerId = playerId;
    await store.dispatch('getPlayer', { config, sessionToken });
  }
  const vm = new Vue({
    router,
    store,
    beforeCreate() {
      if (webViewIntegration.isActive()) {
        webViewIntegration.init();
        window.WebAppListener.sendMessage('Slave.Init');
      }
    },
    render: (h) => h(App),
  });

  vm.$mount('#app');
})();
